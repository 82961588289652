.footer {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: black;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;

  .footerLabel {
    color: white;
    font-weight: 500;
    align-self: center;
  }
}
