:root {
  --primary: #0099d6;
  --primary-light: #04b8ffe3;
  --secondary: #c2c7cf;
  --white: #ffffff;
  --lightGray: #f3f3f3;
  --gray: #ecf0f1;
  --amplify-primary-color: #0099d6;
  --amplify-primary-tint: #c2c7cf;
  --amplify-primary-shade: #393b40;
  --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  --border-radius: 5px;
}

@import "~antd/dist/antd.less";

body {
  background-color: var(--lightGray);
  overflow-x: hidden;
}

.inventory-tabs-container {
  border-radius: 10px;
  .ant-tabs-nav {
    background-color: var(--primary);
    border-radius: 10px 10px 0px 0px;
  }

  .ant-tabs-card {
    > .ant-tabs-nav {
      .ant-tabs-tab {
        &:first-child {
          border-radius: 10px 0 0 0;
        }
        + .ant-tabs-tab {
          margin-left: 0;
        }
      }
      .ant-tabs-tab {
        border: 0;
        border-radius: 0;
        margin-left: 0;
        background: var(--primary);
        color: var(--white);
      }
      .ant-tabs-tab-active {
        background: var(--white);
        .ant-tabs-tab-btn {
          color: black;
          font-weight: 500;
        }
      }
    }
    .ant-tabs-content-holder {
      padding: 10px 20px 20px;
    }
  }
}

td.ant-table-column-sort {
  background: inherit;
}

.hover-gray:hover {
  color: rgba(255, 255, 255, 0.856) !important;
}

.ant-tabs-tab-active {
  svg {
    fill: var(--primary);
  }
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: var(--primary);
  color: white;
}

@primary-color: #0099D6;@border-radius-base: 5px;